import Parse from 'parse'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import fbLogoImg from '../../assets/facebook-small-circle-blue.svg'
import googleLogoImg from '../../assets/google-white-icon.svg'
import { APPID, FB_LOGIN_APP_ID, GOOGLE_CLIENT_ID, SERVER_URL } from '../../constants/ApiEndpoints'
import { facebookLogin, googleLogin } from '../../redux/actions/AuthActions'
import { logEvent } from '../../utils/firebase'

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  padding: 9px 8px;
  background: #000000;
  border-radius: 20px;
  border: none;
  height: 34px;
  cursor: pointer;

  .logo-container {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  span {
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
    font-size: 10px;
    font-weight: 500;
  }
`

const FacebookButton = styled.button`
  height: 34px;
  border-radius: 20px;
  background: white;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`

const parseInit = async () => {
  Parse.initialize(APPID)
  Parse.serverURL = SERVER_URL
}

const loadFacebookSDK = async () => {
  if (typeof window === 'undefined') return

  parseInit()

  window.fbAsyncInit = async () => {
    Parse.FacebookUtils.init({
      appId: FB_LOGIN_APP_ID,
      cookie: true,
      xfbml: true,
      version: 'v3.2'
    })
  }
  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = '//connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
}

parseInit()
loadFacebookSDK()

const SocialLoginSmall = ({ isSignIn }) => {
  const dispatch = useDispatch()
  const fbLogin = e => {
    e.preventDefault()
    dispatch(facebookLogin)
  }

  const successResponseGoogle = response => {
    dispatch(googleLogin(response))
  }

  const errorResponseGoogle = response => {
    if (response.error) {
      logEvent('google_login_error_' + response.error)
    }
  }

  return (
    <ButtonsContainer>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={successResponseGoogle}
        onFailure={errorResponseGoogle}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <div className="logo-container">
              <img src={googleLogoImg} alt="Google" />
            </div>
            <span>Sign in with Google</span>
          </GoogleButton>
        )}
      />
      <FacebookButton onClick={fbLogin}>
        <img src={fbLogoImg} alt="Facebook" />
      </FacebookButton>
    </ButtonsContainer>
  )
}

export default SocialLoginSmall
