import styled from 'styled-components'

export const AppPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
`

export const PopupContent = styled.div`
  position: relative;
  background: white;
  border-radius: 10px;
  padding: 48px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 340px;
  margin-top: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
`

export const PopupLogo = styled.img`
  width: 64px;
  height: 64px;
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 4px solid white;
`

export const PopupTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 12px;
  color: #000;
  line-height: 1.2;
`

export const PopupDescription = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0 0 24px;
  line-height: 1.4;
`

export const GetAppButton = styled.button`
  background: linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  color: white;
  border: none;
  border-radius: 37px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 16px;
  transition: background 0.2s;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(270deg, #ff9d74 0%, #ff5e6d 37.4%, #ff4f7e 74.69%, #ff6d90 100%);
  }
`

export const AuthLinks = styled.div`
  font-size: 14px;
  color: #666;

  span {
    margin: 0 4px;
  }
`

export const AuthLink = styled.button`
  background: none;
  border: none;
  color: #0066ff;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 19px;
  color: #000;
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  z-index: 1;
  opacity: 0.5;

  &:hover {
    opacity: 0.7;
  }
`
