import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dmIconCircle from '../../assets/dmIconCircle.png'
import { useIsMobile } from '../../hooks/useIsMobile'
import { gotoLogIn, gotoSignUp } from '../../redux/actions/AuthActions'
import { logEvent } from '../../utils/firebase'
import { AppPopupWrapper, AuthLink, AuthLinks, CloseButton, GetAppButton, PopupContent, PopupDescription, PopupLogo, PopupTitle } from './MobileAppPopup.styles'

const POPUP_DISMISSED_KEY = 'dm_mobile_app_popup_dismissed'

const MobileAppPopup = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isMobile === null) return // Wait for isMobile to be determined

    // const isDismissed = localStorage.getItem(POPUP_DISMISSED_KEY) === 'true'
    const isDismissed = false
    setIsVisible(isMobile && !isDismissed)
  }, [isMobile])

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem(POPUP_DISMISSED_KEY, 'true')
  }

  const handleGetApp = () => {
    logEvent('mobile_app_popup_cta_clicked')
    window.location.href = 'https://decormatters.app.link/i7yzrclXVQb'
  }

  const handleLogIn = () => {
    logEvent('mobile_app_popup_login_clicked')
    dispatch(gotoLogIn())
  }

  const handleSignUp = () => {
    logEvent('mobile_app_popup_signup_clicked')
    dispatch(gotoSignUp())
  }

  if (!isVisible || isAuthenticated) return null

  return (
    <AppPopupWrapper>
      <PopupContent>
        <CloseButton onClick={handleClose}>✕</CloseButton>
        <PopupLogo src={dmIconCircle} alt="DecorMatters Logo" />
        <PopupTitle>
          DecorMatters is better
          <br />
          in the app
        </PopupTitle>
        <PopupDescription>Design rooms, play games, and transform spaces with AI.</PopupDescription>
        <GetAppButton onClick={handleGetApp}>Get the App</GetAppButton>
        <AuthLinks>
          <AuthLink onClick={handleLogIn}>Log in</AuthLink>
          <span>or</span>
          <AuthLink onClick={handleSignUp}>sign up</AuthLink>
        </AuthLinks>
      </PopupContent>
    </AppPopupWrapper>
  )
}

export default MobileAppPopup
